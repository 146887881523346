import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../components/layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <br/><br/><br/>
      <h1 className="display-1 text-muted mb-7">Page Not Found</h1>
        <p className="lead">Seems you're looking for something that doesn't exist.</p>
        <br />
        <button className="btn btn-secondary w-150 mr-2" type="button" onClick={() => window.history.back()}>Go back</button>
        <a className="btn btn-secondary w-150" href="/"> Home</a>
      </Container>
    </Page>
  </IndexLayout>
)

export default NotFoundPage
