import * as React from 'react'

export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
  return (
    <footer className="footer text-white bg-dark">
      <div className="container">
        <div className="row gap-y align-items-center">
          <div className="col-md-6 text-center text-md-left">
            <small>© InkCloud 2020, All rights reserved.</small>
          </div>

          <div className="col-md-6 text-center text-md-right">
            <div className="social">
              <a className="social-facebook" href="https://www.facebook.com/inkcloud">
                <i className="fa fa-facebook"></i>
              </a>
              <a className="social-twitter" href="https://twitter.com/inkcloud9">
                <i className="fa fa-twitter"></i>
              </a>
              {/* <a className="social-instagram" href="#">
                <i className="fa fa-instagram"></i>
              </a>
              <a className="social-youtube" href="#">
                <i className="fa fa-youtube"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
