import * as React from 'react'
import Helmet from 'react-helmet'
import { withPrefix, StaticQuery, graphql } from 'gatsby'
import 'modern-normalize'
import '../../styles/normalize'

import '../../../saas-theme/src/assets/scss/page.scss';
import 'html5-device-mockups/dist/device-mockups.min.css';

import Navbar from '../Landing/Navbar'
import LayoutRoot from '../LayoutRoot'
import Footer from '../Landing/Footer';

interface IndexLayoutProps {
  navBarColor?: 'navbar-dark' | 'navbar-light'
  navBarStickColor?: 'navbar-stick-dark' | 'navbar-stick-light'
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      siteUrl: string;
      image: string;
      twitterUsername: string;
    }
  }
}

const IndexLayout: React.FC<IndexLayoutProps> = ({ 
  children, 
  navBarColor = 'navbar-dark',
  navBarStickColor = 'navbar-stick-dark'
}) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            twitterUsername
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      return (
      <LayoutRoot>
        <Helmet
          htmlAttributes={{ lang : 'en' }}
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        >
        <link rel="preconnect" href="https://assets.calendly.com"></link>
        <script src={withPrefix('page.min.js')} type="text/javascript" />
        <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet"></link>        


        <meta property="og:url" content="inkcloud9.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />

        <meta property="og:image" content={data.site.siteMetadata.image}  />
        <meta name="og:image" content={data.site.siteMetadata.image}  />

        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:description" content={data.site.siteMetadata.description} />
        <meta property="twitter:image" content={data.site.siteMetadata.image} />
          {/* <link rel="preload" href="styles.css" as="style" onLoad="this.onload=null;this.rel='stylesheet'"></link>
          <noscript><link rel="stylesheet" href="styles.css"></noscript>          */}
        </Helmet>
        <Navbar color={navBarColor} stickColor={navBarStickColor} />
        
        {children}
        <Footer />
      </LayoutRoot>
    );
  }}
  />
)

export default IndexLayout



